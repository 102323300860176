<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries">
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template></TableFilters
    >
    <TableLayout
      to-raw="controller"
      :loading="isDateLoaded"
      :table-data="sourceObject.results"
      :header="header"
      :source-object="sourceObject"
    />
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsControllers from "@/helpers/tableCols/controllers";

export default {
  name: "StaffControllers",
  data() {
    return {
      loadingBtn: false,
      queries: query.controllers
    };
  },
  components: { TableFilters, TableLayout },
  computed: {
    sourceObject() {
      return this.$store.getters.getUserControllersList;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    header() {
      return tableColsControllers.filter(el =>
        el.userRole.includes(this.$store.getters.getCurrentRole)
      );
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: {
        ...this.urlQuery.query,
        object__service_group__specialist: this.urlQuery.id
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchStaffControllersList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query,
            object__service_group__specialist: this.urlQuery.id
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchStaffControllersList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadPageControllersList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    }
  }
};
</script>

<style scoped></style>
