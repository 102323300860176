<template>
  <MainLayout>
    <template v-slot:navbar-title>{{
      sourceObject.results ? sourceObject.results.full_name : ""
    }}</template>
    <template v-slot:navbar-btn-wrap>
      <DeletePopup
        name="Персонал"
        :title="sourceObject.results ? sourceObject.results.first_name : ''"
        :delete-function="deleteServiceStaff"
      />
      <router-link
        v-if="urlQuery && urlQuery.id"
        :to="`/usersGeneralEdit/` + urlQuery.id"
        class="btn btn-edit btn_color_grey"
        >редактировать</router-link
      >
    </template>
    <SlideLayout :tabs="slideTabs">
      <template v-slot:info>
        <div class="keep__title">
          <p class="keep__text">Общая информация</p>
        </div>
        <BasicTableWithoutHeader
          v-if="sourceObject.results"
          :table-data="getDataRequisites"
        >
          <template #item.2.value="{item}">
            <div
              :style="{ color: item.value.position == 50 ? '#95C23D' : '#000' }"
            >
              {{ item.value.position == 50 ? "Администратор" : "Техник" }}
            </div>
          </template>
          <template #item.3.value="{item}">
            <div
              :style="{ color: item.value.is_active ? '#95C23D' : '#EB5C6D' }"
            >
              {{ item.value.is_active ? "Активен" : "Не Активен" }}
            </div>
          </template>
        </BasicTableWithoutHeader>
      </template>
      <template v-slot:objects>
        <StaffObject />
      </template>
      <template v-slot:controllers>
        <StaffControllers />
      </template>
    </SlideLayout>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";

import DeletePopup from "@/components/popup/DeletePopup";
import StaffObject from "@/components/info/staff/StaffObject";
import StaffControllers from "@/components/info/staff/StaffControllers";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import phoneFilter from "@/helpers/filters/phoneFilter";

export default {
  name: "StaffInfosPages",
  components: {
    StaffControllers,
    StaffObject,
    SlideLayout,
    MainLayout,
    BasicTableWithoutHeader,
    DeletePopup
  },
  data: () => ({
    type: "users"
  }),
  computed: {
    slideTabs() {
      return [
        {
          text: "Общая информация",
          value: "info",
          icon: null,
          count: null
        },
        {
          text: "Объекты",
          value: "objects",
          icon: null,
          count: this.sourceObject.results.objects_count
        },
        {
          text: "Контроллеры",
          value: "controllers",
          icon: null,
          count: this.sourceObject.results.controllers_count
        }
      ];
    },
    sourceObject() {
      return this.$store.getters.getStaffInfo;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    getDataRequisites() {
      return [
        {
          text: "ФИО",
          value: this.sourceObject.results.full_name
        },
        {
          text: "Телефон",
          value: phoneFilter.execute(this?.sourceObject?.results?.phone_number)
        },
        {
          text: "Роль",
          value: this.sourceObject.results
        },

        {
          text: "Статус",
          value: this.sourceObject.results
        },
        {
          text: "Группа объектов",
          value:
            this.sourceObject.results.object_service_groups &&
            this.sourceObject.results.object_service_groups.length > 0
              ? this.sourceObject.results.object_service_groups.reduce(
                  (arr, servG) => {
                    arr += servG + "<br/>";
                    return arr;
                  }
                )
              : "-"
        },
        {
          text: "ID",
          value: this.sourceObject.results.id
        }
      ];
    }
  },
  methods: {
    deleteServiceStaff() {
      const org = [50].includes(this.$store.getters.getCurrentRole)
        ? this.$store.getters.getCurrentOrganization
        : this.$route.params.serviceOrganizationId;
      this.$store
        .dispatch("deleteServiceStaff", {
          id: this.urlQuery.id,
          service_org: org
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.staff)
          );
        });
    },
    deleteUser() {
      this.$store
        .dispatch("deleteUser", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.staff)
          );
        });
    },
    banUser() {
      this.$store
        .dispatch("banUser", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.ban(successMessageDictionary.staff)
          );
        });
    }
  },
  created() {
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: {
        ...this.urlQuery.query,
        type: this.type
      }
    });
    let organization_pk =
      this.$store.getters.getCurrentRole === 50
        ? this.$store.getters.getCurrentOrganization
        : this.$route.params.serviceOrganizationId;
    this.isDateLoaded = true;
    this.$store
      .dispatch("fetchServiceStaffById", { organization_pk })
      .finally(() => {
        this.isDateLoaded = false;
      });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        id: this.urlQuery.id,
        query: {
          ...to.query
        }
      });
      let organization_pk =
        this.$store.getters.getCurrentRole === 50
          ? this.$store.getters.getCurrentOrganization
          : this.$route.params.serviceOrganizationId;
      this.isDateLoaded = true;
      this.$store
        .dispatch("fetchServiceStaffById", { organization_pk })
        .finally(() => {
          this.isDateLoaded = false;
        });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearUsersPage").then(() => next());
  }
};
</script>

<style scoped></style>
